@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.scrollable-table-container {
  height: '100%';
  max-height: 500px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  table {
    margin-top: 0;
    tbody.orderbook {
      font-variant-numeric: tabular-nums;

      tr {
        cursor: pointer;
      }

      td {
        line-height: 1.375rem !important;
      }
    }
  }
}
