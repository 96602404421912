  @import '../../assets/styles/colors.scss';
  @import '../../assets/styles/mixins.scss';


  .form-control {
    position: relative;
    margin-bottom: 1rem;
    border: none;

    svg {
      max-width: 30px;
    }
    .label {
      font-size: .75rem;
      margin-bottom: 0;
      font-weight: 300;
      font-size: 13px;
      color: #000;
      position: relative;
      top: 5px;
      opacity: 1;
      @include transition(0.15s);
    }
    .label-active {
      opacity: 1;
      @include transition(0.35s);
    }
    .label-error {
      opacity: 1;
      @include transition(0.35s);

      div {
        animation: shake 0.35s cubic-bezier(.51,.01,.3,1) forwards;
        transform: translate3d(0, 0, 0);
        color: darken($surf-pink, 30%);
      }
    }
    .group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      align-items: stretch;
      position: relative;
      flex-wrap: nowrap;
      width: 100%;
      height: 62px;
      

      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        -webkit-text-fill-color: black;
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
      }

      & > div:not(.icon) {
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 0;
        @include transition(0.2s);
      }

      input {
        &::placeholder {
          color: $surf-gray-3;
          font-weight: 400;
          font-size: 1rem;
          text-overflow: ellipsis;
          opacity: 1;
        }
        &::-webkit-input-placeholder {
          color: $surf-gray-3;
          font-weight: 400;
          font-size: 1rem;
          text-overflow: ellipsis;
          opacity: 1;
        }
        &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
          color: $surf-gray-3;
          font-weight: 400;
          font-size: 1rem;
          text-overflow: ellipsis;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: $surf-gray-3;
          font-weight: 400;
          font-size: 1rem;
          text-overflow: ellipsis;
          opacity: 1;
        }

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type=number] {
          -moz-appearance: textfield;
        }

        /* Firefox */
        &[type=number]::-webkit-outer-spin-button,
        &[type=number]::-webkit-inner-spin-button {
          opacity: 1;
        }
      }
      .input-component-active {
        padding-left: 0;
        @include transition(0.2s);
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $surf-gray-4;
        transform: translateY(3px);
        margin-right: .75rem;
        flex-basis: 0;
        flex-grow: 0;
        position: absolute;
        left: 0;
        top: 1.25rem;
      }
    }
  }
  @keyframes shake {
    0%, 90% {
      transform: translate3d(0, 0, 0);
    }
    
    75% {
      transform: translate3d(2px, 0, 0);
    }

    50% {
      transform: translate3d(-3px, 0, 0);
    }

    25%{
      transform: translate3d(4px, 0, 0);
    }
  }