@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';


.picker {
  position: relative;
  margin-bottom: 1rem;

  svg {
    max-width: 30px;
  }
  .label {
    font-size: .75rem;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 13px;
    color: #000;
    position: relative;
    top: 5px;
    opacity: 0;
    @include transition(0.15s);
  }
  .label-active {
    opacity: 1;
    @include transition(0.35s);
  }
  .label-error {
    opacity: 1;
    @include transition(0.35s);

    div {
      animation: shake 0.35s cubic-bezier(.51,.01,.3,1) forwards;
      transform: translate3d(0, 0, 0);
      color: darken($surf-pink, 30%);
    }
  }
  .group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    flex-wrap: nowrap;
    width: 100%;
    height: 62px;

    .input {
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      padding-left: 0;
      padding-right: 0;

      &::placeholder {
        color: $surf-gray-3;
        font-weight: 400;
        font-size: 1rem;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &::-webkit-input-placeholder {
        color: $surf-gray-3;
        font-weight: 400;
        font-size: 1rem;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
        color: $surf-gray-3;
        font-weight: 400;
        font-size: 1rem;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: $surf-gray-3;
        font-weight: 400;
        font-size: 1rem;
        text-overflow: ellipsis;
        opacity: 1;
      }

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }

      /* Firefox */
      &[type=number]::-webkit-outer-spin-button,
      &[type=number]::-webkit-inner-spin-button {
        opacity: 1;
      }
    }
    .select {
      visibility: hidden;
    }
    .dropdown-button {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .dropdown-button-icon {
        width: 2rem;
        margin-right: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 1.5rem;
          transform: translateY(-2px);
        }
      }
      .dropdown-button-body {
        line-height: 1rem;
        padding-top: 2px;
        margin-right: 1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 3.5rem;

        span {
          color: #ABABBB;
          font-size: 0.75rem;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .dropdown-button-chevron {
        opacity: 0.3;
        @include transition(0.15s);
        margin-right: .5rem;
        transform: translateY(2px);
      }
    }

    .dropdown-button:hover {
      .dropdown-button-chevron {
        opacity: 0.8;
        @include transition(0.35s);
      }
    }
  }
  .dropdown-menu {
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    visibility: hidden;
    z-index: 10;
    opacity: 0;
    @include transition(0s);
    
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(134, 135, 160, 0.05), 0px 4px 4px rgba(134, 135, 160, 0.05), 0px 8px 8px rgba(134, 135, 160, 0.05), 0px 16px 16px rgba(134, 135, 160, 0.1), 0px 48px 48px rgba(134, 135, 160, 0.1);
    border-radius: .5rem;
    overflow: hidden;

    &:after {
      content: '';
      bottom: 0;
      left: 0;
      height: 1.5rem;
      width: 100%;
      // background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      z-index: 1000;
      position: absolute;
    }

    .dropdown-menu-header {
      height: 54px;
      padding: 1.125rem .875rem;
      font-size: 0.75rem;
      font-weight: 500;
      opacity: 0.5;
      text-transform: uppercase;
      letter-spacing: .2rem;
      color: $surf-gray-3;
      background-color: saturate($gray-300, 10%);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: .5rem .5rem 0 0;

      svg {
        transform: translateX(.25rem) translateY(-3px);

        * {
          fill: $surf-gray-3;
        }
      }
    }
    .dropdown-menu-body {
      max-height: 220px;
      overflow: scroll;
      width: 100%;
      position: relative;

      .option {
        height: 62px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;
        box-shadow: inset 0 -1px 0 0 $gray-200;
        cursor: pointer;

        &:hover {
          background-color: desaturate(darken($surf-gray-background, 1%), 0%);
        }

        &.option-selected {
          &, &:hover, &:active {
            background-color: $surf-cobalt;
            color: #ffffff;
            cursor: default;
            pointer-events: none;
            box-shadow: none;
          }
        }
        &.option-disabled {
          &, &:hover, &:active {
            background-color: desaturate(darken($surf-gray-background, 1%), 0%);
            color: $gray-400;
            cursor: default;
            pointer-events: none;
            box-shadow: inset 0 -1px 0 0 rgba($gray-200, 0.7);
          }
        }

        .option-icon {
          width: 2rem;

          svg {
            height: 1.4rem;
            margin-left: .5rem;
            opacity: .25;
          }
        }

        .option-label {
          margin-left: 1rem;
          font-size: 1.125rem;
          font-weight: 600;
          width: 2.5rem;
        }

        .option-title {
          margin-left: 1.5rem;
          font-size: 0.875rem;
          opacity: 0.5;
        }
      }
    }
  }
  .dropdown-menu-active {
    visibility: visible;
    opacity: 1;
    @include transition(0s);
  }
}
@keyframes shake {
  0%, 90% {
    transform: translate3d(0, 0, 0);
  }
  
  75% {
    transform: translate3d(2px, 0, 0);
  }

  50% {
    transform: translate3d(-3px, 0, 0);
  }

  25%{
    transform: translate3d(4px, 0, 0);
  }
}