@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';


.input {
  position: relative;
  margin-bottom: 1rem;

  svg {
    max-width: 30px;
  }
  .label {
    font-size: .75rem;
    padding: .5rem 0;
    font-weight: 300;
    font-size: 13px;
    color: $gray;
    position: relative;
    opacity: 1;
    @include transition(0.15s);
  }
  .label-active {
    opacity: 1;
    @include transition(0.35s);
  }
  .label-error {
    opacity: 1;
    @include transition(0.35s);

    div {
      animation: shake 0.35s cubic-bezier(.51,.01,.3,1) forwards;
      transform: translate3d(0, 0, 0);
      color: darken($surf-pink, 30%);
    }
  }
  .group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    align-items: stretch;
    position: relative;
    flex-wrap: nowrap;
    width: 100%;
    height: 40px;
    background-color: var(--page-bg);
    border-radius: .75rem;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: $white;
      -webkit-box-shadow: 0 0 0px 1000px $dark inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .units {
      pointer-events: none;
      padding: 0.625rem 0.75rem;
    }
    .clear {
      cursor: pointer;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0.5rem;

      .clear-button {
        width: 1.25rem;
        height: 1.25rem;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $dark;
        border-color: $gray-light;
        background-color: $gray-light;
        opacity: 0;
        visibility: hidden;
        @include transition(0.1s);

        &.active {
          opacity: 1;
          visibility: visible;
          @include transition(0.25s);
        }
      }
    }
    .units, .clear {
      position: absolute;
      right: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.13px;
      color: $gray-extralight;
    }

    .input-component {
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      padding-left: 0.75rem;
      padding-right: 4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      @include transition(0.2s);
      background-color: var(--page-bg);
      border-radius: .75rem;
      border: none;
      color: var(--text-color);//$white;
      box-shadow: none;
      outline: none;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.13px;

      &:focus, &:focus-visible {
        box-shadow: 0 0 0 3px rgba(saturate(adjust-hue($blue, 10), 30), .5);
      }

      &::placeholder {
        color: $gray;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.13px;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &::-webkit-input-placeholder {
        color: $gray;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.13px;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
        color: $gray;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.13px;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: $gray;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.13px;
        text-overflow: ellipsis;
        opacity: 1;
      }

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }

      /* Firefox */
      &[type=number]::-webkit-outer-spin-button,
      &[type=number]::-webkit-inner-spin-button {
        opacity: 1;
      }
    }
    .input-component-active {
      @include transition(0.2s);
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $surf-gray-4;
      margin-right: .75rem;
      flex-basis: 0;
      flex-grow: 0;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 2.25rem;
      pointer-events: none;
      color: $gray;
    }
  }
  &.input-disabled {
    .units {
      color: $dark-gray;
    }

    .input-component {

      &::placeholder {
        color: $dark-gray;
      }
      &::-webkit-input-placeholder {
        color: $dark-gray;
      }
      &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
        color: $dark-gray;
      }
      &:-ms-input-placeholder {
        color: $dark-gray;
      }
    }
  }

  &.input-icon {
    .input-component {
      padding-left: 2.5rem;
    }
  }
}
@keyframes shake {
  0%, 90% {
    transform: translate3d(0, 0, 0);
  }

  75% {
    transform: translate3d(2px, 0, 0);
  }

  50% {
    transform: translate3d(-3px, 0, 0);
  }

  25%{
    transform: translate3d(4px, 0, 0);
  }
}
