$dark: #1C2125;
$dark-alt: #20262A;
$dark-gray: #3B4043;
$gray: #676B6E;
$gray-light: #8c8f91;
$gray-extralight: #ADB0B1;
$white: #ffffff;
$green-alt: #2FA851; //#36C05C;
$green: #36C05C;
$blue: #0083E0;
$red: #e22626;

$main: #0d6efd;

$SURF_BLUE: #0083E0;
$SURF_PINK: #FF7B86;
$SURF_RED: #e23b3b;
$SURF_YELLOW: #F1CD5E;
$SURF_GREEN: #36C05C;

$SURF_WHITE: #FFFFFF;
$SURF_GRAY_BACKGROUND: #F4F4F5;
$SURF_GRAY: #20262A;
$SURF_GRAY_2: #707376;
$SURF_BLACK: #000000;
$SURF_TERTIARY: #838588;
$SURF_SECONDARY: #707376;

$tertiary: #b6b8ba;
$text-secondary: #adb0b1;


$SURF_GRADIENT_BACKGROUND: #dcdce0;
$SURF_GRADIENT_BACKGROUND_WAVE: #e5e5e7;


$surf-blue: #0083e0;
$surf-cobalt: #3888FF;
$surf-pink: #FF7B86;
$surf-yellow: #F1CD5E;
$surf-green: #88BF26;

$white: #FFFFFF;
$surf-gray-background: #fafafc;
$surf-gray: #20262A;
$surf-gray-2: #707376;
$surf-gray-3: #6b707e;
$surf-gray-4: #a2a9b9;

// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #fafafc;
$gray-200: #e9eaef;
$gray-300: #dedfe6;
$gray-400: #ced2da;
$gray-500: #adb3bd;
$gray-600: #6c717d;
$gray-700: #494d57;
$gray-800: #343840;
$gray-900: #212329;
$black:    #000;

// Create your own map
$ui-kit-colors: (
  "cerise": #E33F84,
  "red-violet": #A62E99,
  "purple-heart": #681EB0,
  "daisy-blush": #4E1AA6,
  "blue-gem": #35179C,
  "governor-bay": #3D3BC1,
  "royal-blue": #4963E6,
  "cornflower-blue": #5B95E8,
  "blue-sky": #70C7EC,
);

// Create your own map
$surf-colors: (
  "blue": $surf-blue,
  "red": $surf-pink,
  "green": $surf-green,
  "cyan": rgb(176, 225, 233),
  "yellow": rgb(241, 205, 94)
);