@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';


.tooltip {
  position: absolute;
  z-index: 9;
  margin-bottom: 1rem;
  width: auto;
  color: #ffffff;
  background: #000000cc;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(3px);
  padding: .25rem .5rem .3rem;
  transform: translate(1rem, 1rem);
  border-radius: 4px;
  pointer-events: none;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -0.04px;
  white-space: pre-wrap;
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  float: left;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
}

.active {
  opacity: 1;
  visibility: visible;
}
