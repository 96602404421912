@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.header {
  position: relative;

  a {
    svg {
      vertical-align: middle;
      transform: translateY(-1px);
    }
  }

  .navbar {
    padding-top: .75rem;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .navbar-brand {
      position: relative;
      z-index: 1065;
      padding-left: .75rem;
      padding-right: 1rem;
      padding-top: 0;
      padding-bottom: 0;

      @media (max-width: 575px) {
        padding-left: 0.5rem;
      }

      a {
        font-size: 1rem;
        font-weight: 500;
        color: var(--text-color);//$white;
        display: block;
        padding: 0.45rem;
        padding-right: 0.5rem;
        border-radius: 1rem;

        span {
          @media (max-width: 575px) {
            display: none;
          }
        }

        svg {
          vertical-align: middle;
          transform: translateY(-1px);
          margin-left: 5px;
          margin-right: 12px;
        }
      }
    }
    .btn {
      border-radius: 20px;
      padding: 0.4rem 1.25rem;
      position: relative;
      z-index: 20;
    }
    .navbar-collapse {
      flex-grow: 1;
      margin-left: 1rem;
      margin-right: .75rem;
      color: var(--text-color);//$white;

      @media (max-width: 575px) {
        padding-top: 0.5rem;
        display: none;
      }

      .navbar-nav {
        flex-grow: 1;
        @include transition(0.15s);
        box-shadow: 0px 0px 0px 0 rgba(33,39,56,0.01);

        & > * {
          margin-left: 15px;

          @media (max-width: 575px) {
            margin-left: 0;
          }
        }

      }

    }
    .surf-toggle {
      z-index: 1060;
    }
    .nav-item {
      & > a {
        & {
          color: var(--text-color);//$white;
          font-weight: 500;

          &:after {
            content: none;
          }

          sup {
            color: $SURF_GREEN;
            color: #2FA851;
          }

          &:hover, &.active {
            color: $SURF_BLUE;

            sup {
              color: $SURF_BLUE;
            }
          }

          @media (max-width: 575px) {
            padding: 1rem 0.25rem;
            position: relative;

            &:after {
              position: absolute;
              top: 1.25rem;
              right: 0.5rem;
            }
          }
        }
      }
      &:not(.show) {
        a {
          & {
            @media (max-width: 575px) {
              // border-bottom: 1px solid rgba($SURF_TERTIARY, 0.2);
            }
          }
        }
      }
      &.navbar-dropdown {
        @media (max-width: 575px) {
          position: static;
        }
        @media (max-width: 335px) {
          position: static;
        }

        &, &:hover, &:focus, &:active {
          &, &:hover, &:focus, &:active {
            color: var(--text-color);//#fff;
            border: none;
            background: none;
            outline: none;
            box-shadow: none;
          }
        }

        &:after {
          content: none;
        }
        &:hover {
          color: rgba($white, 0.7);
        }
        & > svg {
          width: 18px;
          height: 18px;
          vertical-align: middle;
          margin-top: -1px;
          margin-left: 0.25rem;
        }
        & > div {
          @include transition(0.5s);
          //background: rgba(var(--page-bg), 0.85);
          background: var(--panel-bg);
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          border-radius: 0.75rem;
          overflow: hidden;
          color: var(--text-color);//$white;
          min-width: 320px;
          z-index: 1065;
          transform: translateY(-20px);
          box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.01), 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 4px 4px rgba(0, 0, 0, 0.03), 0px 8px 8px rgba(0, 0, 0, 0.03), 0px 16px 16px rgba(0, 0, 0, 0.03), 0px 32px 32px rgba(0, 0, 0, 0.03);

          @media (max-width: 575px) {
            transform: translateX(calc(-50%));
            left: 50%;
          }

          @media (max-width: 335px) {
            transform: translateX(0);
            top: 50px;
            left: .75rem;
            right: .75rem;
          }

          & > a {
            line-height: 2rem;
            &:hover, &:focus, &:active {
              &:hover, &:focus, &:active {
                color: var(--text-color);//$white;
                background: rgba($white, 0.07);
              }
            }
          }
        }
      }
    }

    a {
      &.nav-link {
        color: var(--text-color);//$white;
        font-weight: 500;

        &:hover, &.active {
          color: $SURF_BLUE;
        }
        svg {
          margin-right: 0.5rem;
          vertical-align: middle;
          width: 1rem;
          height: 1rem;
          margin-top: -1px;
        }
        &.nav-link-default {
          &:hover, &:focus, &:active {
            &:hover, &:focus, &:active {
              //color: rgba($white, 0.7);
            }
          }
        }
      }
      &.update-link {
        color: #0d6efd;
        z-index: 1060;
      }
    }
    .navbar-toggler {
      border: none;
      position: relative;
      width: 54px;
      height: 40px;
      z-index: 2;
      margin-right: 0.5rem;

      &, &:hover, &:focus, &:active {
        &, &:hover, &:focus, &:active {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }

      @media (max-width: 575px) {
        padding-top: 0.5rem;
        display: none;
      }

      span {
        background-image: none;
        display: none;
      }

      &:before, &:after {
        content: '';
        height: 2px;
        width: 24px;
        background-color: $gray;
        position: absolute;
        @include transition(0.15s);
        left: 15px;
      }
      &:before {
        top: 15px;
      }
      &:after {
        top: 21px;
      }

      &:not(.collapsed) {
        &:before {
          transform: rotate(45deg) translateY(2px) translateX(2px);
        }
        &:after {
          transform: rotate(-45deg) translateY(-2px) translateX(2px);
        }
      }

      &:hover {
        &:before, &:after {
          background-color: $blue;
          @include transition(0.35s);

        }
      }
    }
  }
  .header-panel {
    & > *:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  .pairs-menu {
    tr {
      cursor: pointer;
      @include transition(0.1s);

      &:hover {
        color: rgba($gray-extralight, 1);
        @include transition(0.1s);
      }
    }
    td {
      white-space: nowrap;
      padding-right: 8px;
    }
    td:last-child {
      padding-right: 0px;
    }
    button {
      &, &:hover, &:active, &:focus {
        &, &:hover, &:active, &:focus {
          border: none;
          background: none;
          outline: none;
          color: inherit;
          padding: 0;
          margin: 0;
          margin-right: 3px;
          transform: translateY(-1px);
          cursor: pointer;
        }
      }
      & {
        svg {
          margin-right: 0.25rem;
        }
        svg, svg path {
          stroke: currentColor;
          //fill: none;
        }
        &:hover {
          &, &:hover, &:active, &:focus {
            svg path {
              stroke: #fff !important;
              //fill: $blue;
              @include transition(0.1s);
            }
          }
        }
      }
      &.active {
        color: white;

        svg path {
          fill: white;
        }
        &:hover {
          &, &:hover, &:active, &:focus {
            svg path {
              stroke: #fff !important;
              //fill: $blue !important;
              @include transition(0.1s);
            }
          }
        }
      }
    }
  }
}
