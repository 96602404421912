@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.pair-stats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .pair-stats-header {
    color: $gray;
    margin-bottom: 0.125rem;
    white-space: nowrap;
  }
  .pair-stats-body {
    color: var(--text-color);//$white;
    white-space: nowrap;
  }
  &.up {
    .pair-stats-body {
      color: $green;
    }
  }
  &.down {
    .pair-stats-body {
      color: $red;
    }
  }
}

.pair-stats-skeleton {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 1.25rem;

  .pair-stats-body {
    width: 100%;
  }
  .pair-stats-header {
    width: 50%;
  }
}
