@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

table {
  tbody.orderbook {
    font-variant-numeric: tabular-nums;
    
    td {
      line-height: 1.375rem !important;
    }
  }
}
form {
  button {
    width: 100%;

    &:before, &:after {
      content: none !important;
    }

    &.btn-buy {
      margin-top: 1rem;
      background-color: $green;
      border-color: $green;
      box-shadow: none !important;

      &:hover {
        &, &:active, &:focus {
          background-color: desaturate(darken($green, 5), 10);
          border-color: desaturate(darken($green, 5), 10);
        }
      }

      &:focus {
        &, &:active, &:focus {
          background-color: desaturate(darken($green, 5), 10);
          border-color: desaturate(darken($green, 5), 10);
          box-shadow: 0 0 0 0.2rem rgba($green, 0.3);
        }
      }

      &:disabled, &.disabled {
        &, &:active, &:hover, &:focus {
          background-color: $green;
          border-color: $green;
          box-shadow: none;
          opacity: .5;
          cursor: default;
        }
      }
    }
    &.btn-sell {
      margin-top: 1rem;
      background-color: $red;
      border-color: $red;
      box-shadow: none !important;
  
      &:hover {
        &, &:active, &:focus {
          background-color: desaturate(darken($red, 5), 10);
          border-color: desaturate(darken($red, 5), 10);
        }
      }
  
      &:focus {
        &, &:active, &:focus {
          background-color: desaturate(darken($red, 5), 10);
          border-color: desaturate(darken($red, 5), 10);
          box-shadow: 0 0 0 0.2rem rgba($red, 0.3);
        }
      }
      &:disabled, &.disabled {
        &, &:active, &:hover, &:focus {
          background-color: $red;
          border-color: $red;
          box-shadow: none;
          opacity: .5;
          cursor: default;
        }
      }
    }
  }
}
.form-input {
  margin-bottom: .5rem;
}