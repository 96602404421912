@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.footer {
  border-top: 1px solid rgba(#F4F4F5, 0.05);
  height: 72px;
  font-size: 0.925rem;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 70px;
    max-width: none;

    @media (max-width: 499px) {
      flex-direction: column;
    }
  }
  
  @media (max-width: 499px) {
    height: auto;
  }

  .copyright {
    color: $surf-gray;
    opacity: .4;
    @media (max-width: 500px) {
      text-align: center;
    }
  }
  .nav {
    margin-top: 0;
    margin-bottom: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;

    .nav-item {
      margin-right: 15px;
    }
    @media (max-width: 500px) {
      flex-direction: column;
      margin-bottom: 20px;

      .nav-item {
        margin-right: 15px;
        height: 40px;
        text-align: center;
      }
    }
  }
  .dod {
    color: $surf-gray;
    opacity: .4;

    &:hover {
      opacity: 1;
      color: $surf-gray;
    }
  }
}