@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.panel {
  flex-grow: 1;
  min-height: 175px;
  background: var(--panel-bg);//$dark-alt;
  border-radius: 0.75rem;
  text-align: left;
  padding: 0;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;

  .panel-header {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 1rem;
    line-height: 1.5rem !important;
    height: 56px;
    color: var(--text-color);

    a {
      color: $gray;

      &:hover, &:active {
        color: var(--text-color);
      }
    }
  }

  .panel-body {
    padding: 1rem;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
  }
  .panel-header + .panel-body {
    padding-top: 0;
  }
}
