@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.toast-container {
  position: fixed !important;

  .toast {
    width: 350px;
    max-width: 100%;
    font-size: 0.875rem;
    pointer-events: auto;
    background-clip: padding-box;
    border: none;
    border-radius: 1rem;
    @include transition(0.3s);
    background: rgba($dark-alt, 80%);
    -webkit-backdrop-filter: blur(10px);
    color: white;
    backdrop-filter: blur(10px);
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.01), 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 16px 16px rgba(0, 0, 0, 0.05), 0px 24px 24px rgba(0, 0, 0, 0.1);

    .toast-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0.75rem 1rem 0;
      color: white;
      background: none !important;
      background-clip: padding-box;
      border: none;

      span {
        flex-grow: 10;
      }

      button {
        flex-grow: 0;
        &, &:hover, &:focus, &:active {
          &, &:hover, &:focus, &:active {
            margin-right: -.35rem;
            background: none !important;
            border: none !important;
            outline: none !important;
            color: white;
          }
        }
      }
    }
    .toast-body {
      padding: 1rem;
      word-wrap: break-word;
    }
  }
}