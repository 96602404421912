@import './colors.scss';
@import './mixins.scss';

:root {
  /* Light mode */
  --light-panel-bg: #ffffff;
  --light-page-bg: #f5f7fc;
  --light-text-color: #000000;
  --light-switch-icon: url("../images/ico-theme/theme-light.png");
  --light-switcher-bg: #cccccc;
  --light-text-gray: #b2b5be;
  --light-modal-bg: rgba(255, 255, 255, 0.7);

  /* Dark mode */
  --dark-panel-bg: #20262A;
  --dark-page-bg: #1C2125;
  --dark-text-color: #ffffff;
  --dark-switch-icon: url("../images/ico-theme/theme-dark-1.png");
  --dark-switcher-bg: #1C2125;
  --dark-text-gray: #676B6E;
  --dark-modal-bg: rgba(28, 33, 37, 0.7);

  /* Default mode */
  --page-bg: var(--light-page-bg);
  --panel-bg: var(--light-panel-bg);
  --text-color: var(--light-text-color);
  --switch-icon: var(--light-switch-icon);
  --switcher-bg: var(--light-switcher-bg);
  --text-gray: var(--light-text-gray);
  --modal-bg: var(--light-modal-bg);
}

#theme-switch {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* Switched mode */
#theme-switch:checked ~ #page {
  --page-bg: var(--dark-page-bg);
  --panel-bg: var(--dark-panel-bg);
  --text-color: var(--dark-text-color);
  --switch-icon: var(--dark-switch-icon);
  --switcher-bg: var(--dark-switcher-bg);
  --text-gray: var(--dark-text-gray);
  --modal-bg: var(--dark-modal-bg);
}

#theme-switch:checked ~ .modal-overlay {
  --page-bg: var(--dark-page-bg);
  --panel-bg: var(--dark-panel-bg);
  --text-color: var(--dark-text-color);
  --switch-icon: var(--dark-switch-icon);
  --switcher-bg: var(--dark-switcher-bg);
  --text-gray: var(--dark-text-gray);
  --modal-bg: var(--dark-modal-bg);
}

#theme-switch:checked ~ .modal-backdrop {
  --page-bg: var(--dark-page-bg);
  --panel-bg: var(--dark-panel-bg);
  --text-color: var(--dark-text-color);
  --switch-icon: var(--dark-switch-icon);
  --switcher-bg: var(--dark-switcher-bg);
  --text-gray: var(--dark-text-gray);
  --modal-bg: var(--dark-modal-bg);
}


.switch-label {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
}

.switch-label::before {
  content: var(--switch-icon);
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
}

#page {
  min-height: 100%;
  background-color: var(--page-bg);
  color: var(--text-color);
}

html {
  font-size: 16px;
  //background-color: $dark;
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  letter-spacing: -.01em;
  //background-color: $dark;
  //color: $white;
}

a {
  color: $main;

  &, &:focus, &:active, &:hover {
    text-decoration: none;
    outline: none;
  }
  &:focus, &:active, &:hover {
    @include transition(0.35s);
    color: darken($main, 10);
  }
  &.active {
    color: $main;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

html, body, #root, .ws-app {
  /* IE 10-11 didn't like using min-height */
  height: 100%;
}
.ws-app {
  display: flex;
  flex-direction: column;
}

.container, .container-fluid {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media (max-width: 575px) {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

main {
  position: relative;
  flex: 1 0 auto;
  padding-bottom: 1rem;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;

  // div {
  //   color: $gray;
  //   margin-bottom: 1rem;
  // }
  // svg {
  //   margin-right: 0.7rem;
  //   color: $white;
  // }
  // span {
  //   color: $white;
  // }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > * {
      flex-grow: 0;

      &:last-of-type {
        flex-grow: 1;
      }

      @media (max-width: 1399px) {
        &:not(.d-none-xl):last-of-type {
          flex-grow: 1;
        }
      }
      @media (max-width: 1199px) {
        &:not(.d-none-lg):last-of-type {
          flex-grow: 1;
        }
      }
    }
  }
}

b {
  font-weight: 600;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.loader-spinner {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $surf-gray-2;
  color: $surf-gray-2;
  -webkit-animation: dot-flashing 1s infinite linear alternate;
  animation: dot-flashing 1s infinite linear alternate;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.loader-spinner::before, .loader-spinner::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.loader-spinner::before {
  left: 30px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $surf-gray-2;
  color: $surf-gray-2;
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.loader-spinner::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $surf-gray-2;
  color: $surf-gray-2;
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}

@-webkit-keyframes dot-flashing {
  0% {
    background-color: $surf-gray-2;
  }
  50%,
  100% {
    background-color: lighten($surf-gray-2, 40);
  }
}

@keyframes dot-flashing {
  0% {
    background-color: $surf-gray-2;
  }
  50%,
  100% {
    background-color: lighten($surf-gray-2, 40);
  }
}

form, .form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.terms {
  &, &:active, &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    color: #A2A9B9;
    border-bottom: 1px dashed #A2A9B9;
    font-size: 13px;
    margin: 10px auto 15px;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    display: inline-block;
    position: relative;
    @include transition(0.15s);

    &:hover {
      @include transition(0.3s);
      color: darken(#A2A9B9, 30);
      border-bottom: 1px dashed #A2A9B9;
    }

    &:before {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: -24px;
      content: '';
      height: 24px;
      width: 24px;
      background-repeat: no-repeat;
      background-position: left center;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00021 14.8572C11.7873 14.8572 14.8574 11.7871 14.8574 8.00003C14.8574 4.21293 11.7873 1.14288 8.00021 1.14288C4.21311 1.14288 1.14307 4.21293 1.14307 8.00003C1.14307 11.7871 4.21311 14.8572 8.00021 14.8572Z' stroke='%23A2A9B9' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.94531 3.68573V9.7143' stroke='%23A2A9B9' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.00021 12.5715C8.4736 12.5715 8.85735 12.1877 8.85735 11.7143C8.85735 11.2409 8.4736 10.8572 8.00021 10.8572C7.52682 10.8572 7.14307 11.2409 7.14307 11.7143C7.14307 12.1877 7.52682 12.5715 8.00021 12.5715Z' fill='%23A2A9B9'/%3E%3C/svg%3E%0A");
    }
  }
}

.accept {
  color: #A2A9B9;
  font-size: 13px;
  margin-top: 30px;

  svg {
    display: inline-block;
    margin-left: 20px;
  }

  em {
    color: #000;
  }
}

.modal-backdrop.fade {
  //background: rgba($dark, 0.8);
  background: var(--modal-bg);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 100%;
  top: 1px;
  opacity: 0;
  visibility: hidden;
  height: calc(100vh - 1px);
  @include transition(0.25s);

  &.show {
    visibility: visible;
    opacity: 1;
    @include transition-property(0.4s, opacity, 0);
  }
}
.menu-backdrop {
  z-index: 1061;
}
.modal {
  .modal-dialog {
    .modal-content{
      border-radius: 1.25rem;
      box-shadow: 0px -1px 2px rgba(12, 13, 14, 0.015), 0px 2px 2px rgba(9, 9, 10, 0.02), 0px 4px 4px rgba(12, 12, 14, 0.02), 0px 8px 8px rgba(7, 8, 8, 0.02), 0px 16px 16px rgba(11, 11, 12, 0.07), 0px 48px 48px rgba(22, 22, 26, 0.1);

      .modal-header {
        padding: 0.875rem 1rem;
        border-bottom: 1px solid gray;

        .modal-title {
          font-size: 1rem;
          color: $blue;
          vertical-align: bottom;
          line-height: 1.625rem;
        }

        .logo {
          margin-right: 7px;
          float: left;
          vertical-align: middle;

          svg path {
            fill: $blue;
          }
        }
        button {
          &.btn-close {
            &, &:focus, &:active, &:hover {
              outline: none;
              border: none;
              background-color: transparent;
            }

            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1L1 15' stroke='%230073C4' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 1L15 15' stroke='%230073C4' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            width: 1.625rem;
            height: 1.625rem;
          }
        }
      }
      .modal-body {
        padding: 2rem;
      }
      .modal-footer {}
    }
  }
}
.modal-overlay {
  height: calc(100% - 1px);
  margin-top: 1px;

  .modal-dialog {
    .modal-content {
      border-radius: 0;
      background: transparent;
      width: 100%;

      .modal-footer {
        border-top: none;
      }
    }
  }
  &.fade {
    .modal-dialog {
      transition: transform 0.3s 0.4s cubic-bezier(0.375, 0.885, 0.6, 1);
      transform: translate(0, -20px);
    }
  }
  &.show {
    .modal-dialog {
      transition: transform 0.3s 0s cubic-bezier(0.375, 0.885, 0.6, 1);
      transform: none;
    }
  }
}
.modal-backdrop {
  background-color: $dark;
}


// ================== \\

.market-indicators {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 4rem;
  margin-bottom: 1rem;

  & > * {
    margin-right: 2rem;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    margin-bottom: 2rem;
  }

  .pair {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: var(--text-color);//$white;
    align-items: center;

    @media (max-width: 991px) {
      margin-top: 1.25rem;
      margin-bottom: 1.5rem;
    }

    em {
      color: $gray;
      font-style: normal;
      margin: 0 2px;
    }

    .icons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-right: 0.5rem;

      div {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: #000;
        background-repeat: no-repeat;
        background-size: contain;

        &:last-child {
          transform: translateX(-8px);
        }
      }

      .icon-qube {
        background-image: url('/assets/images/token/qube.svg');
      }

      .icon-tqube {
        background-image: url('/assets/images/token/qube.svg');
      }

      .icon-wqube {
        background-image: url('/assets/images/token/qube.svg');
      }

      .icon-bridge {
        background-image: url('/assets/images/token/bridge.svg');
      }

      .icon-tbridge {
        background-image: url('/assets/images/token/bridge.svg');
      }

      .icon-wbridge {
        background-image: url('/assets/images/token/bridge.svg');
      }

      .icon-dai {
        background-image: url('/assets/images/token/dai.svg');
      }

      .icon-tdai {
        background-image: url('/assets/images/token/dai.svg');
      }

      .icon-wdai {
        background-image: url('/assets/images/token/dai.svg');
      }


      .icon-tsdt {
        background-image: url('/assets/images/token/sdt.svg');
      }

      .icon-tsdc {
        background-image: url('/assets/images/token/sdc.svg');
      }

      .icon-usdt {
        background-image: url('/assets/images/token/sdt.svg');
      }

      .icon-usdc {
        background-image: url('/assets/images/token/sdc.svg');
      }

      .icon-tbtc {
        background-image: url('/assets/images/token/btc.svg');
      }

      .icon-wbtc {
        background-image: url('/assets/images/token/btc.svg');
      }

      .icon-teth {
        background-image: url('/assets/images/token/eth.svg');
      }

      .icon-weth {
        background-image: url('/assets/images/token/eth.svg');
      }

      .icon-ever {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' fill='%230073C4'/%3E%3Cpath d='M9.09172 6.29289L5.70711 9.67751C5.07714 10.3075 5.52331 11.3846 6.41421 11.3846H11.6154C12.1677 11.3846 12.6154 11.8323 12.6154 12.3846V17.5858C12.6154 18.4767 13.6925 18.9229 14.3225 18.2929L17.7071 14.9083C17.8946 14.7207 18 14.4664 18 14.2012V7C18 6.44772 17.5523 6 17 6H9.79883C9.53361 6 9.27926 6.10536 9.09172 6.29289Z' fill='%23FDFDFD'/%3E%3C/svg%3E%0A");
      }

    }
    &.loading {
      .icons {
        margin-right: 0;

        div {
          background-color: $dark-gray;
          opacity: 0.7;
          background-image: none !important;
        }
      }

      .skeleton-pair {
        width: 100px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
          width: 47px;
        }
      }
    }
  }
  .indicators {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    & > * {
      margin-right: 2rem;
    }

    & > .pair {
      margin-right: 3rem;
    }
  }
}

.nav-pills {
  align-items: center;
  height: 100%;
  flex-direction: row;

  .nav-link-pill {
    color: var(--text-gray) !important;
    padding: 0;
    margin-right: 1.5rem;
    cursor: pointer;
    padding-right: 0.25rem;
    padding-left: 0.25rem;

    sup {
      margin-left: .25rem;
    }

    &.active {
      cursor: default;
    }

    &:hover, &.active {
      background-color: transparent;
      color: var(--text-color) !important;//$white !important;
    }
  }
  &.nav-pills-narrow {

    .nav-link-pill {
      margin-right: .5rem;
    }
  }
}

.not-connected {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trading-view {
  min-height: 500px;
}

table {
  width: 100%;
  color: var(--text-color);//$white;
  margin-top: -0.5rem;

  th {
    color: $gray;
    white-space: nowrap;
  }
  th {
    line-height: 2.5rem !important;
  }
  td {
    line-height: 1.375rem !important;
    line-height: 2.5rem !important;
  }
  th, td {
    min-width: 40px;

    em {
      color: $gray;
      font-style: normal;
      margin: 0 2px;
    }
  }
}

.red {
  color: $red;
}
.green {
  color: $green;
}
.faded {
  color: $gray-extralight;
}
.null {
  color: $gray;
}

.left {
  text-align: left;
}
.right {
  text-align: right;
}

button.connect {
  margin: .5rem auto !important;
  display: block;

  svg {
    margin-top: -2px;
    margin-right: .5rem;
  }
}

#root-modal {
  //background-color: var(--modal-bg);
}

.surf-connect {
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 400px;
  overflow: hidden;

  & > div {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    width: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: visibility 0s ease-in-out .3s, width 0s ease-in-out .3s, overflow 0s ease-in-out .3s, opacity 0.3s ease-in-out 0s;

    &.active {
      opacity: 1;
      visibility: visible;
      width: 100%;
      overflow: visible;
      transition: visibility 0s ease-in-out .35s, width 0s ease-in-out .35s, overflow 0s ease-in-out 0.35s, opacity 0.3s ease-in-out .35s;
    }
  }

  h5 {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .qrcode svg {
    width: 256px;
    height: 256px;
    margin-right: 2rem;
    margin-left: -.5rem;
    margin-bottom: .5rem;
  }

  .svg-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    ol {
      & > li {
        max-width: 320px;

        &:before {
          color: $gray;
        }

        svg {
          width: 27px;
          height: 24px;
          margin-top: -3px;
        }
      }
    }
  }
}
.platforms {
  color: $gray-light;
  display: flex;
  flex-direction: row;
  line-height: 4rem !important;
  vertical-align: middle;
  margin: 0 auto !important;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    max-width: 340px;
    margin-left: 2rem;

    li {
      height: 36px;
      white-space: nowrap;
      list-style-type: none;
      background-position: left center;
      background-repeat: no-repeat;
      padding: 0;
      margin: 0;
      font-size: 0.9375rem;
      font-weight: 500;
      letter-spacing: -0.02rem;
      line-height: 2.25rem;
      margin-left: 1.5rem;
      color: $gray-light;
      @include transition(0.35s);

      svg {
        width: 18px;
        height: 18px;
        color: $gray-light;
        margin-right: 0.5rem;
        margin-top: -1px;
        @include transition(0.35s);
      }
      &.ios {
        svg {
          margin-top: -2px;
        }
      }
      &.android {
      }
      &.brave {
      }
      &.github {
      }
      &:hover {
        color: var(--text-color);//$white;

        svg {
          height: 18px;
          color: $blue;
        }
      }
    }
  }
}

.balanced-value {
  display: flex;
  flex-direction: row;
  margin: 1.25rem 0 1.5rem;
  white-space: nowrap;

  & > * {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.nav.tab-switcher {
  background-color: var(--page-bg);//$dark;
  border-radius: 0.75rem;
  padding: 4px;
  height: 40px;
  margin-bottom: 1rem;

  .nav-item {
    text-transform: uppercase;
    flex-grow: 1;
    text-align: center;

    .active {
      border-radius: 10px;
      background-color: $gray;

      &.green {
        background-color: $green-alt;
      }
      &.red {
        background-color: $red;
      }
    }

    &, a {
      .active {
        color: $white;
      }
    }

    &, a {
      border-radius: 10px;
      color: var(--text-color);//$white;
      line-height: 16px;
    }
  }
}
.checkboxes {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: .5rem 0;

  & > *:first-child {
    margin-right: 2rem;
  }
}

.loader-wrapper {
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.FlexChartContainer {
  height: 470px;
  margin-left: -.5rem;
}

.toast-container.position-absolute {
  position: fixed !important;
  z-index: 2000;
}
